import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "styles/mediaQueries";
import InstagramIcon from "icons/instagram-icon.svg";
import FacebookIcon from "icons/facebook-icon.svg";
import LinkedinIcon from "icons/linkedin-icon.svg";
import TwitterIcon from "icons/twitter-icon.svg";
import FooterLogo from "icons/footer-logo-icon.svg";
import ArrowIconBlack from "icons/arrow-black-icon.svg";
import ArrowIcon from "icons/arrow-icon.svg";

export const ContactsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  padding-bottom: 125px;
  width: 100%;
  min-height: 100vh;
  background: #b1aeab;
  ${mediaMax("tablet")} {
    padding: 75px 0;
  }
`;
export const ContactsRow = styled.div``;

export const ContactsTitle = styled.h3`
  max-width: 768px;
  margin: 0 auto 100px;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  ${mediaMax("tabletLandscape")} {
    font-size: 30px;
    padding: 0 4vw;
  }
  ${mediaMax("tablet")} {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 25px;
  }
  ${mediaMax("desktop")} {
    padding: 0 8vw;
  }
`;

export const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 60vw;
  width: 100%;
  margin-bottom: 200px;
  ${mediaMax("desktop")} {
    max-width: 70vw;
    margin-bottom: 100px;
  }
  ${mediaMax("desktopSmall")} {
    margin-bottom: 100px;
    max-width: 100vw;
    padding: 0 4vw;
  }
  ${mediaMax("desktop")} {
    padding: 0 8vw;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  ${mediaMax("tablet")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FormInputWrapper = styled.div`
  max-width: 24vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  &.error {
    border-bottom: 1px solid red;
  }
  ${mediaMax("desktopSmall")} {
    max-width: 40vw;
  }
  ${mediaMax("tablet")} {
    max-width: 100%;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
`;

export const FormInputTitle = styled.span`
  display: inline-block;
  text-align: left;
  margin-bottom: 10px;
  color: #222;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  ${mediaMax("tablet")} {
    display: none;
  }
`;

export const FormWrapEmail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormInput = styled.input`
  width: 100%;

  padding: 16px 0;
  box-sizing: border-box;
  background: transparent;
  border: 0;

  outline: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  &::placeholder {
    color: rgba(34, 34, 34, 0.5);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #b0adaa inset !important;
  }
`;

export const FormInputEmail = styled(FormInput)`
  border: 0 !important;
`;

export const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 15px;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    background: url(${ArrowIconBlack}) no-repeat;
    width: 8px;
    height: 8px;
    margin-left: 2.5px;
  }
  ${mediaMax("tablet")} {
    display: none;
  }
`;
export const SubmitBtnMobile = styled.button`
  position: relative;
  display: none;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  padding: 5px 2px 5px 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: #000;
  border: 0;
  &:after {
    content: "";
    display: block;
    background: url(${ArrowIcon}) no-repeat;
    width: 12px;
    height: 12px;
    margin-left: 8px;
    margin-top: 3px;
    flex-shrink: 0;
  }
  ${mediaMax("tablet")} {
    display: flex;
  }
`;

export const ContactScoialLink = styled.a`
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  &.instagram {
    background-image: url(${InstagramIcon});
  }
  &.twitter {
    background-image: url(${TwitterIcon});
  }
  &.linkedin {
    background-image: url(${LinkedinIcon});
  }
  &.facebook {
    background-image: url(${FacebookIcon});
  }
  ${mediaMax("tablet")} {
    width: 12px;
    height: 12px;
    margin-left: 12px;
    background-size: contain;
  }
`;

export const ContactsInformationWrapper = styled.div`
  max-width: 1920px;
  padding: 0 4vw;
  width: 100%;
  display: flex;
  ${mediaMax("desktopSmall")} {
    flex-direction: column-reverse;
  }
  ${mediaMax("desktop")} {
    padding: 0 8vw;
  }
`;
export const ContactsInformationColumn = styled.div`
  width: 50%;
  ${mediaMax("desktopSmall")} {
    width: 100%;
  }
`;

export const ContactLogo = styled.div`
  width: 100%;
  background-image: url(${FooterLogo});
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 34vw;
  height: 310px;
  margin: 0 auto;

  ${mediaMax("desktop")} {
    margin-top: 40px;
    padding-right: 20px;
    max-width: 35vw;
    height: 20vw;
  }
  ${mediaMax("desktopSmall")} {
    max-width: 50vw;
    height: 20vw;
  }
  ${mediaMax("tablet")} {
    max-width: 70vw;
    height: 35vw;
    padding-right: 0;
  }
`;

export const ContactsInformation = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;
  ${mediaMax("tablet")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContactsMailItem = styled.div`
  margin-bottom: 40px;
  padding-right: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    display: inline-block;
    margin-bottom: 5px;
  }
  span,
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
  }
  ${mediaMax("tablet")} {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #222222;
    &:last-child {
      margin-bottom: 10px;
    }
    span,
    a {
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;
export const ContactsMailContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40vw;
  word-break: break-all;
  text-align: left;
`;

export const ContactsInformationTitle = styled.h6`
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;

  ${mediaMax("tablet")} {
    margin-bottom: 0;
    font-weight: 900;
    font-size: 13px;
    line-height: 15px;
  }
`;

export const ContactsLinks = styled.div`
  ${mediaMax("tablet")} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ContactsMailLink = styled.a``;

export const ContactsMails = styled.div`
  ${mediaMax("tablet")} {
    width: 100%;
  }
`;

export const ContactsSocialLinksWrapper = styled.div`
  margin-top: 36px;
  ${mediaMax("tablet")} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
    border-bottom: 1px solid #222;
    order: 3;
    padding: 10px 0;
  }
`;

export const ContactsSocialLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0 40px;
  ${mediaMax("tablet")} {
    margin: 0;
  }
`;

export const ContactInternakLink = styled(Link)`
  display: flex;
  font-weight: 900;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 40px;
  order: 1;
  &:last-child {
    margin-bottom: 0;
    order: 2;
  }
  ${mediaMax("tablet")} {
    font-size: 13px;
    line-height: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #222;
    width: 100%;
    margin-bottom: 10px;
  }
`;
