import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'
import Header from 'components/header'
import Contacts from 'components/contacts'
import { GlobalStyle } from 'styles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'

import 'styles/fonts.css'

const LayoutContent = ({ children, location }) => {
  return (
    <>
      <TransitionGroup component={null}>
        <Transition
          key={location.pathname}
          timeout={{ enter: 1000, exit: 1000 }}
        >
          {(status) => (
            <div className={`page ${status}`}>
              <LayoutWrapper id="layoutWrapper">{children}</LayoutWrapper>
            </div>
          )}
        </Transition>
      </TransitionGroup>
    </>
  )
}

const Layout = ({ children, location, pageContext: { menu } }) => {
  return (
    <PageWrapper>
      <GlobalStyle />
      <ContentWrapper>
        <LayoutContent location={location}>{children}</LayoutContent>
      </ContentWrapper>
      <Contacts />
      <Header menu={menu} />
    </PageWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
