import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "../../styles/mediaQueries";

import HeaderArrowIcon from "icons/arrow-icon.svg";
import InstagramIcon from "icons/instagram-white-icon.svg";
import TwitterIcon from "icons/twitter-white-icon.svg";
import FacebookIcon from "icons/facebook-white-icon.svg";
import LinkedinIcon from "icons/linkedin-white-icon.svg";
import HamburgerIcon from "icons/hamburger-menu-icon.svg";
import CloseIcon from "icons/close-white-icon.svg";

export const HeaderWrapper = styled.header`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #222222;
  z-index: 99;

  ${mediaMax("tabletLandscape")} {
    display: none;
  }
`;

export const HeaderNavigation = styled.nav`
  display: flex;
  justify-content: space-betwen;
  width: 100%;
`;

export const HeaderLink = styled((props) => <Link {...props} />)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 12px;
  line-height: 50px;
  text-transform: uppercase;
  color: #ffffff;
  ${mediaMax("desktop")} {
    font-size: 12px;
  }
  &:before {
    content: "";
    position: absolute;
    right: -18px;
    top: 20px;
    width: 9px;
    height: 7px;
    background-image: url(${HeaderArrowIcon});
    background-repeat: no-repeat;
  }
`;

export const LogoWrapper = styled(HeaderLink)`
  width: 10%;
  &:before {
    display: none;
  }
  ${mediaMax("tablet")} {
    img {
      height: 35px;
    }
  }
`;

export const HeaderMenuWrapper = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-around;
`;

//mobile header

export const MobileHeaderShort = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: none;
  z-index: 99;
  ${mediaMax("tabletLandscape")} {
    display: block;
  }
`;
export const MobileHeaderFull = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  background: #222;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  transition: all 0.5s;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  &.open {
    opacity: 1;
    z-index: 999;
  }
`;

export const MobileHeaderTop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 34px;
  background: #222;
  height: 55px;
`;
export const MobileHeaderButton = styled.button`
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #222;
  width: 30px;
  height: 30px;
  background: transprent;
  border: 0;

  cursor: pointer;

  background: url(${HamburgerIcon}) no-repeat center;
  &.close-button {
    background: url(${CloseIcon}) no-repeat center;
  }
`;

export const MobileHeaderMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #222;
  padding: 40px 32px;
  transition: 0.25s all;
  overflow: hidden;
`;
export const MobileHeaderLogo = styled((props) => <Link {...props} />)``;
export const MobileHeaderMenuItem = styled((props) => <Link {...props} />)`
  padding: 12px 0;
  display: block;
  color: #fff;
  font-weight: 900;
  font-size: 15px;
  line-height: 25px;
  border-bottom: 1px solid #80888c;
  text-decoration: none;
  text-transform: uppercase;
`;

export const HeaderSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  height: 34px;
  color: #222;
`;

export const MobileHeaderSocialsLinksWrapper = styled.div`
  display: flex;
  padding: 12px 0;
  margin: 0 32px;
  border-bottom: 1px solid #80888c;
  border-top: 1px solid #80888c;
  justify-content: space-between;
  margin-top: auto;
`;

export const MobileHeaderSocialsLinksTitle = styled.div`
  color: #fff;
  font-weight: 900;
  font-size: 15px;
  line-height: 25px;
  text-decoration: none;
  text-transform: uppercase;
`;
export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 10px;
  &.instagram {
    background: url(${InstagramIcon}) center;
    background-size: cover;
  }
  &.twitter {
    background: url(${TwitterIcon}) center;
    background-size: cover;
  }
  &.linkedin {
    background: url(${LinkedinIcon}) center;
    background-size: cover;
  }
  &.facebook {
    background: url(${FacebookIcon}) center;
    background-size: cover;
  }
`;
