import * as React from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { useForm } from "react-hook-form";
import submitForm from "helpers/submitForm";
import {
  ContactsWrapper,
  ContactsRow,
  ContactsTitle,
  FormWrapper,
  ContactsInformationWrapper,
  ContactsLinks,
  ContactsInformationColumn,
  ContactsInformation,
  ContactsMailItem,
  ContactsMailLink,
  ContactsMails,
  ContactScoialLink,
  ContactsMailContent,
  ContactsInformationTitle,
  ContactInternakLink,
  ContactsSocialLinks,
  ContactsSocialLinksWrapper,
  Form,
  FormInputWrapper,
  FormInput,
  FormInputEmail,
  SubmitBtn,
  SubmitBtnMobile,
  ContactLogo,
  FormWrapEmail,
  FormInputTitle,
} from "./index.styled";

const Contacts = () => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    submitForm({
      name: data.name,
      email: data.email,
    });
    setIsSuccess(true);
  };

  return (
    <ContactsWrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration={1}>
        <ContactsRow>
          <ContactsTitle>
            Join our newsletter for the latest updates and events at Baltimore
            Peninsula
          </ContactsTitle>
        </ContactsRow>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration={1}>
        <ContactsRow>
          <FormWrapper>
            {!isSuccess ? (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormInputWrapper className={errors.name && "error"}>
                  <FormInputTitle>NAME*</FormInputTitle>
                  <FormInput
                    placeholder="First and Last Name"
                    {...register("name", { required: true })}
                  />
                </FormInputWrapper>
                <FormInputWrapper
                  className={[errors.email && "error", " email-form-wrap"]}
                >
                  <FormInputTitle>EMAIL*</FormInputTitle>
                  <FormWrapEmail>
                    <FormInputEmail
                      placeholder="email@domain.com"
                      {...register("email", { required: true })}
                    />

                    <SubmitBtn className="desktop">SUBMIT</SubmitBtn>
                  </FormWrapEmail>
                </FormInputWrapper>

                <SubmitBtnMobile className="mobile button">
                  SUBMIT
                </SubmitBtnMobile>
              </Form>
            ) : (
              <div className="center">
                <h3>Thank you</h3> <br />
                <p>Your form has been submitted successfully.</p>
              </div>
            )}
          </FormWrapper>
        </ContactsRow>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration={1}>
        <ContactsRow>
          <ContactsInformationWrapper>
            <ContactsInformationColumn>
              <ContactLogo />
            </ContactsInformationColumn>
            <ContactsInformationColumn>
              <ContactsInformation>
                <ContactsMails>
                  <ContactsMailItem>
                    <ContactsInformationTitle>
                      General Inquiries
                    </ContactsInformationTitle>
                    <ContactsMailContent>
                      <span>410.962.1500</span>
                      {/* <br /> */}
                      <ContactsMailLink href="mailto:info@magpartners.com">
                        info@magpartners.com
                      </ContactsMailLink>
                    </ContactsMailContent>
                  </ContactsMailItem>
                  <ContactsMailItem>
                    <ContactsInformationTitle>
                      Media INQUIRIES
                    </ContactsInformationTitle>
                    <ContactsMailContent>
                      <ContactsMailLink href="mailto:BALTIMOREPENINSULA@berlinrosen.com">
                        BALTIMOREPENINSULA@berlinrosen.com
                      </ContactsMailLink>
                    </ContactsMailContent>
                  </ContactsMailItem>
                </ContactsMails>

                <ContactsLinks>
                  <ContactInternakLink to="/contractopportunies">
                    Contract Opportunities
                  </ContactInternakLink>

                  <ContactsSocialLinksWrapper>
                    <ContactsInformationTitle>
                      FOLLOW US ON SOCIAL
                    </ContactsInformationTitle>
                    <ContactsSocialLinks>
                      <ContactScoialLink
                        aria-label="instagram"
                        href="https://www.instagram.com/baltimorepeninsula"
                        target="_blank"
                        className="instagram"
                      />
                      <ContactScoialLink
                        href="https://www.twitter.com/balt_peninsula"
                        target="_blank"
                        className="twitter"
                        aria-label="twitter"
                      />
                      <ContactScoialLink
                        href="https://www.facebook.com/baltimorepeninsula/"
                        target="_blank"
                        className="facebook"
                        aria-label="facebook"
                      />
                      <ContactScoialLink
                        href="https://www.linkedin.com/company/baltimore-peninsula/"
                        target="_blank"
                        className="linkedin"
                        aria-label="linkedin"
                      />
                    </ContactsSocialLinks>
                  </ContactsSocialLinksWrapper>
                  {/* <ContactInternakLink to="/legal">Legal</ContactInternakLink> */}
                </ContactsLinks>
              </ContactsInformation>
            </ContactsInformationColumn>
          </ContactsInformationWrapper>
        </ContactsRow>
      </AnimationOnScroll>
    </ContactsWrapper>
  );
};

export default Contacts;
