import styled from "styled-components";

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const PageWrapper = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .page {
    top: 0;
    transition: opacity 1s;
    height: 100%;
    width: 100%;
  }
  .page.entering,
  .page.entered {
    position: relative;
    opacity: 1;
  }
  .page.exiting,
  .page.exited {
    position: absolute;
    opacity: 0;
  }
`;
