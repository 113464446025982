import { createGlobalStyle } from "styled-components";

import animate from "./animate";
// import fonts from "./fonts";
import slider from "./slick-slider";

export const GlobalStyle = createGlobalStyle`
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"); */

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    scrollbar-width: none;
    
  }

  *::-webkit-scrollbar {
     display: none;
  }


  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    background-color: #ffffff;
    color: #000000;
    overflow-x: hidden;
  }
  body > div {width: 100%;}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter', verdana, sans-serif;
    font-weight: 100;
  }

  body,
  p,
  a,
  button,
  ul,
  form {
    font-family: 'Inter', verdana, sans-serif;
    font-weight: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
    &.underline {
      text-decoration: underline;
    }
  }

  .center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .italics {
    font-style: italic;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  ${slider}
  ${animate}
`;
