const breakpoints = {
  phone: 375,
  phoneLarge: 414,
  phoneXL: 576,
  tablet: 768,
  tabletLandscape: 1024,
  desktopSmall: 1250,
  desktop: 1440,
  desktopLarge: 1920,
  desktopXLarge: 2048
};

// Usage

// ${mediaMax('tabletLandscape')} {
//   display: none;
// }

// ${mediaMin('tabletLandscape')} {
//   display: none;
// }

const mediaMax = (n) => {
  const bpArray = Object.keys(breakpoints).map((key) => [key, breakpoints[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size - 1}px)`];
    return acc;
  }, []);

  return result;
};

const mediaMin = (n) => {
  const bpArray = Object.keys(breakpoints).map((key) => [key, breakpoints[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

export { mediaMax, mediaMin };
