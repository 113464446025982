import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import {
  HeaderWrapper,
  HeaderNavigation,
  HeaderLink,
  LogoWrapper,
  HeaderMenuWrapper,
  MobileHeaderShort,
  MobileHeaderTop,
  MobileHeaderMenu,
  MobileHeaderMenuItem,
  MobileHeaderButton,
  MobileHeaderFull,
  MobileHeaderSocialsLinksWrapper,
  MobileHeaderSocialsLinksTitle,
  SocialLinks,
  SocialLink,
} from "./index.styled";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const { strapiMenu: menu } = useStaticQuery(graphql`
    query HeaderQuery {
      strapiMenu {
        Logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        MenuItemsLeft {
          Title
          page {
            Slug
          }
        }
        MenuItemsRight {
          Title
          page {
            Slug
          }
        }
      }
    }
  `);

  const Logo = menu.Logo.localFile.childImageSharp ? (
    <GatsbyImage
      image={getImage(menu.Logo.localFile.childImageSharp)}
      alt="logo"
    />
  ) : (
    <img src={menu.Logo.localFile.publicURL} alt="logo" />
  );

  let menuList = [...menu.MenuItemsLeft, ...menu.MenuItemsRight];

  return (
    <>
      <HeaderWrapper>
        <HeaderNavigation>
          <HeaderMenuWrapper>
            {menu.MenuItemsLeft.map((item, index) => (
              <HeaderLink
                className="first-list"
                key={`menu-${index}`}
                to={`/${item.page.Slug === "index" ? "" : item.page.Slug}`}
              >
                {item.Title}
              </HeaderLink>
            ))}
          </HeaderMenuWrapper>
          <LogoWrapper to="/">{Logo}</LogoWrapper>
          <HeaderMenuWrapper>
            {menu.MenuItemsRight.map((item, index) => (
              <HeaderLink
                className="second-list"
                key={`menu-${index}`}
                to={`/${item.page.Slug === "index" ? "" : item.page.Slug}`}
              >
                {item.Title}
              </HeaderLink>
            ))}
          </HeaderMenuWrapper>
        </HeaderNavigation>
      </HeaderWrapper>

      <MobileHeaderShort>
        <MobileHeaderTop>
          <MobileHeaderButton
            onClick={() => setOpen(true)}
            className={"open-button"}
          ></MobileHeaderButton>

          <LogoWrapper to="/">{Logo}</LogoWrapper>
        </MobileHeaderTop>
      </MobileHeaderShort>

      <MobileHeaderFull className={[open ? "open " : " "]}>
        <MobileHeaderTop>
          <MobileHeaderButton
            onClick={() => setOpen(false)}
            className={"close-button"}
          ></MobileHeaderButton>

          <LogoWrapper to="/" onClick={() => setOpen(false)}>
            {Logo}
          </LogoWrapper>
        </MobileHeaderTop>
        <MobileHeaderMenu>
          {menuList.map((item, index) => (
            <MobileHeaderMenuItem
              onClick={() => {
                setOpen(false);
              }}
              key={`menu-mobile-${index}`}
              to={`/${item.page.Slug === "index" ? "" : item.page.Slug}`}
            >
              {item.Title}
            </MobileHeaderMenuItem>
          ))}
          {/* <MobileHeaderMenuItem to={`/legal`}>legal</MobileHeaderMenuItem> */}
        </MobileHeaderMenu>
        <MobileHeaderSocialsLinksWrapper>
          <MobileHeaderSocialsLinksTitle>
            FOLLOW US ON SOCIAL
          </MobileHeaderSocialsLinksTitle>
          <SocialLinks>
            <SocialLink
              aria-label="instagram"
              href="https://www.instagram.com/baltimorepeninsula"
              target="_blank"
              className="instagram"
            />
            <SocialLink
              href="https://www.twitter.com/balt_peninsula"
              target="_blank"
              className="twitter"
              aria-label="twitter"
            />
            <SocialLink
              href="https://www.facebook.com/baltimorepeninsula/"
              target="_blank"
              className="facebook"
              aria-label="facebook"
            />
            <SocialLink
              href="https://www.linkedin.com/company/baltimore-peninsula"
              target="_blank"
              className="linkedin"
              aria-label="linkedin"
            />
          </SocialLinks>
        </MobileHeaderSocialsLinksWrapper>
      </MobileHeaderFull>
    </>
  );
};

export default Header;
